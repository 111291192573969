<template>
  <v-card flat max-width="1000px">
    <v-container>
      <v-row>
        <v-col>
          <v-card-text>
            <div class="font-weight-bold text-left">
              {{ $t("table.farmName") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.desc_fazenda }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.zone") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.cd_zona }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.field") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.cd_talhao }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.serviceOrder") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.ordem_servico }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.hectareCartography") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.ha_cartografia }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.workedArea") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.vl_area_trabalhada }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.totalProduction") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.vl_produtividade }}
            </p>
            <div class="font-weight-bold text-left">
              {{ $t("table.realTCH") }}
            </div>
            <p class="text-left text--primary">
              {{ dados.vl_tch_estimado }}
            </p>
          </v-card-text>
        </v-col>
        <v-col>
          <ProductivityChart
            :faixa="faixas"
            :dados="dados"
            style="margin: 0 auto;"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ProductivityChart from "./ProductivityChart";
export default {
  name: "ChartDialog",
  components: {
    ProductivityChart
  },
  props: {
    faixas: {
      type: Array,
      default: () => []
    },
    dados: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({})
};
</script>

<style>
.apexcharts-menu-item {
  min-width: 125px !important;
}
</style>
