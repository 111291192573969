<template>
  <v-app>
    <v-content>
      <v-row>
        <v-col class="pa-8">
          <ProductivityTable
            :locale="locale"
            :report="reportData"
            v-if="showReport"
          />
        </v-col>
      </v-row>
      <transition name="wrapper-fade">
        <div
          class="fake-overlay"
          @click.self="showFilter = false"
          v-show="showFilter"
        >
          <FilterPane
            :url="url"
            :clientId="cdCliente"
            :userId="cdUsuario"
            :unityId="cdUnidade"
            @setFechamentos="setFechamentos"
            @setLegenda="setLegenda"
            :locale="locale"
          />
        </div>
      </transition>
      <transition name="wrapper-fade">
        <div
          class="fake-overlay"
          @click.self="showFechamentos = false"
          v-show="showFechamentos"
        >
          <FechamentosPane
            :fechamentos="fechamentos"
            @selectedFechamentos="setSelectedFechamentos"
            @goBack="goBack"
          />
        </div>
      </transition>

      <div
        style="position:fixed; bottom: 60px; right:0;"
        class="flex flex-column"
      >
        <v-btn class="mx-2" fab dark color="#1e88e5" @click="toggleFilter()">
          <v-icon dark>mdi-filter</v-icon>
        </v-btn>
      </div>
    </v-content>
  </v-app>
</template>

<script>
import ProductivityTable from "./components/ProductivityTable";
import FilterPane from "./components/FilterPane";
import FechamentosPane from "./components/FechamentosPane";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./helpers/injectScripts";
import axios from "axios";
import { locales } from "./const/locales";
import sortNewObj from "./mixins/sortnewObj";

export default {
  mixins: [sortNewObj],
  name: "app",
  i18n,
  vuetify,
  components: {
    ProductivityTable,
    FilterPane,
    FechamentosPane
  },

  data: () => ({
    data: [
      {
        rendimento: 25,
        deslocamento: 10
      },
      {
        rendimento: 35,
        deslocamento: 15
      },
      {
        rendimento: 45,
        deslocamento: 12
      },
      {
        rendimento: 55,
        deslocamento: 5
      },
      {
        rendimento: 70,
        deslocamento: 7
      },
      {
        rendimento: 80,
        deslocamento: 9
      },
      {
        rendimento: 90,
        deslocamento: 6
      },
      {
        rendimento: 110,
        deslocamento: 15
      },
      {
        rendimento: 40,
        deslocamento: 10
      }
    ],
    showFilter: true,
    showGraph: false,
    showFechamentos: false,
    destroyChart: false,
    legenda: 0,
    fechamentos: [],
    selectedFechamentos: [],
    reportData: [],
    showReport: false
  }),

  props: {
    cdCliente: {
      type: Number,
      default: () => 113
    },
    cdUnidade: {
      type: Number,
      default: () => 4
    },
    cdUsuario: {
      type: String,
      default: () => "douglas.silva"
    },
    locale: {
      type: String,
      default: () => process.env.VUE_APP_I18N_LOCALE
    },
    grupo: {
      type: Number,
      default: () => 0
    }
  },

  computed: {
    url() {
      return process.env.VUE_APP_API_URL + (this.grupo == 0 ? "" : `-${this.grupo}`)
    },
    relUrl() {
      return process.env.VUE_APP_REPORT_API_URL + (this.grupo == 0 ? "" : `-${this.grupo}`);
    }
  },

  methods: {
    destroyGraph() {
      this.showGraph = false;
      this.destroyChart = true;
    },
    buildChart() {
      this.showGraph = true;
      this.destroyChart = false;
    },
    setFechamentos(v) {
      this.fechamentos = v;
      this.showFilter = false;
    },
    setLegenda(v) {
      this.legenda = v;
      this.showFechamentos = true;
    },
    setSelectedFechamentos(v) {
      this.selectedFechamentos = v;
      this.showFechamentos = false;
    },
    goBack() {
      this.showFechamentos = false;
      this.toggleFilter();
    },
    async requestEstimatedTCH() {
      let data = {
        clientId: this.cdCliente,
        unityId: this.cdUnidade,
        legendId: this.legenda,
        ids: this.selectedFechamentos
      };
      let url = this.relUrl + "/v3/report/tch-estimated-field";
      let aux = [];
      await axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(res => (aux = res.data))
        .catch(err => {
          throw new Error("Erro ao buscar relatório de produtividade", err);
        });

      this.reportData = this.sortNewObj(aux);
      this.reportData = this.reportData.sort(function(a, b) {
        if (a.ordem_servico < b.ordem_servico) {
          return -1;
        }
        if (a.ordem_servico > b.ordem_servico) {
          return 1;
        }
        return 0;
      });
      this.showReport = true;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      this.selectedFechamentos = [];
      this.showReport = false;
    }
  },

  watch: {
    locale: {
      handler: function(value) {
        // Antes de setar o currentLocale, checar se existe na lista de locales
        if (locales.indexOf(value) > -1) {
          this.$i18n.locale = value;
        } else {
          this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
        }
      },
      immediate: true
    },
    selectedFechamentos: {
      handler: function() {
        this.requestEstimatedTCH();
      },
      deep: true
    }
  }
};
</script>
<style>
* {
  font-size: 1em !important;
}
.fake-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100vw;
  width: auto;
}
.wrapper-fade-enter-active,
.wrapper-fade-leave-active {
  transition: opacity 0.3s;
}
.wrapper-fade-enter,
.wrapper-fade-leave-to {
  opacity: 0;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css");
@import url("https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/apexcharts/3.16.0/apexcharts.min.css");
</style>
