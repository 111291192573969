export default {
  methods: {
    sortNewObj(obj_a_mudar) {
      /*
           Essa função irá retornar um novo objeto ordenado por faixas
        */
      let newArray = []; //Array cujos objetos serão inseridos ao final
      //
      //
      // json_retorno é o objeto a ser modificado
      //
      let i = new Date().getMilliseconds();
      obj_a_mudar.forEach(function(linha) {
        //
        let aux = []; //variável auxiliar para separar o intervalo e as properties do obj FAIXAS
        //
        Object.entries(linha.faixas).forEach(([key, value]) => {
          //
          let intervalo = key.split("-"); //separando o intervalo
          let element = {};
          //
          element.pr_inter = parseFloat(intervalo[0]); //é necessário executar o parseFloat para executar o sort()
          element.valor = value;
          element.nome = key;
          //
          aux.push(element);
          //
        });

        let newObj = {}; //Variável para guardar os objetos que serão inseridos em newArray
        /*
                Esta parte irá ordenar as faixas
            */
        newObj.faixas = aux.sort((a, b) => (a.pr_inter > b.pr_inter ? 1 : -1));
        /*
                Esta parte irá montar uma string para ser usada na criação do objeto FAIXAS
            */
        let json_text = "{";

        newObj.faixas.forEach(function(item) {
          json_text += '"' + item.nome + '":' + item.valor.toFixed(1) + ",";
        });

        json_text = json_text.substr(0, json_text.length - 1) + "}";
        json_text = JSON.parse(json_text);
        /*
                Esta parte irá montar o novo Array de Objetos
            */
        newObj.faixas = json_text;
        newObj.cd_zona = linha.cd_zona;
        newObj.cd_talhao = linha.cd_talhao;
        newObj.cd_fazenda = linha.cd_fazenda;
        newObj.desc_talhao = linha.desc_talhao;
        newObj.desc_fazenda = linha.desc_fazenda;
        newObj.ordem_servico = linha.ordem_servico;
        newObj.ha_cartografia = linha.ha_cartografia;
        newObj.vl_produtividade = linha.vl_produtividade;
        newObj.vl_area_trabalhada = linha.vl_area_trabalhada;
        newObj.vl_tch_estimado = linha.vl_tch_estimado;
        newObj.virtual_id = i;
        newObj.rowType = "normal";
        i++;
        //
        newArray.push(newObj);
      });

      return newArray;
    }
  }
};
